import 'bootstrap'
import Vue from 'vue'
import Multiselect from '../Multiselect.vue'
import Rails from "@rails/ujs"
import '../scss/application.scss'

Rails.start()

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.vue-multiselect')
  for (let el of elements) {
    const name = el.dataset.name
    const options = JSON.parse(el.dataset.options)
    const selected = JSON.parse(el.dataset.selected)
    const multiple = el.dataset.multiple !== 'false'
    const props = { name, options, selected, multiple, classes: [...el.classList] }
    new Vue({ el, render: h => h(Multiselect, { props }) })
  }
});

<template>
  <div :class="classes">
    <select multiple :name="name" style="display: none">
      <option v-for="option in value" :value="option.id" selected>{{ option.name }}</option>
    </select>

    <vue-multiselect
        :options="options"
        v-model="value"
        :multiple="multiple"
        :searchable="true"
        :close-on-select="!multiple"
        placeholder=""
        label="name"
        key="id"
        track-by="id"
        selectedLabel=""
        selectLabel=""
        deselectLabel=""
    ></vue-multiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'Multiselect',
  components: {
    VueMultiselect,
  },
  props: {
    name: { type: String },
    selected: { type: Array, default: () => [] },
    options: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: true },
    classes: { type: Array, default: () => [] }
  },
  data() {
    return { value: this.selected }
  }
}
</script>
